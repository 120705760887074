import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function Policy({ data }) {
    return (
        <Layout slug="/policy/" {...data.meta.nodes[0]}>
            <div class="c-page-title">
                <h1>プライバシーポリシー</h1>
            </div>

            <div class="p-terms">
                <div class="p-section">

                <section>
                <p>当社は、「Goodな発想で、世界をHappyに。」を企業理念とし、健康・医療・介護・福祉の領域を対象にした事業を展開しております。<br />
                利用者の皆様の信頼を得るために、当社は適切な個人情報保護に関する取組みを実行いたします。</p>
                </section>

                <section>
                    <ol>
                        <li>当社は、個人情報保護の重要性を強く認識し、個人情報を適切に取得、利用いたします。<br />当社では、あらかじめご本人の同意を得ることなく利用目的の達成に必要な範囲を超えた個人情報の取り扱いを行わないことを原則とし、そのための措置を講じます。</li>
                        <li>当社は、ご本人の同意を得た場合や法令等により正当な理由がある場合を除き、個人情報を第三者に提供いたしません。</li>
                        <li>当社は、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守します。</li>
                        <li>当社は、保有する個人情報の漏えい、滅失又はき損を防止するため、対策を講じるとともに、必要な是正措置を講じます。</li>
                        <li>当社は、個人情報の取扱い及び個人情報保護マネジメントシステムに関する苦情・相談窓口を設置し、対応いたします。</li>
                        <li>当社は、JIS規格（JISQ15001）に基づく個人情報保護マネジメントシステムを運用し、継続的に見直し、改善していきます。</li>
                    </ol>
                </section>

                <section>
                <p>制定日：2004年5月15日<br />
                最終改定日：2020年10月01日<br />
                株式会社グッピーズ<br />
                代表取締役社長 肥田 義光</p>
                </section>

                <section>
                <p>プライバシーポリシーに関するお問い合わせ先<br />
                個人情報保護管理者 伊良皆 教弘<br />
                E-mail： service@guppy.co.jp<br />
                TEL： 03-5908-3880(平日10：00～18：00)<br />
                FAX： 03-5908-3881</p>
                </section>

                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        meta: allMeta(filter: {slug: {eq: "/policy/"}}) {
            nodes {
                title
                description
                keywords
            }
        }
    }
`